import {Component} from '@angular/core';
import {ILoadingOverlayAngularComp} from 'ag-grid-angular';
import {ILoadingOverlayParams} from 'ag-grid-community';

@Component({
  selector: 'app-loading-overlay',
  standalone: true,
  template: `
    <div>
      <img src="../../../../../assets/icons/green-spinner.svg" width="60" alt="Spinner"/>
    </div>
  `,
})
export class CustomLoadingOverlayComponent
  implements ILoadingOverlayAngularComp {
  public params!: ILoadingOverlayParams & { loadingMessage: string };

  agInit(params: ILoadingOverlayParams & { loadingMessage: string }): void {
    this.params = params;
  }
}
