import {Component} from '@angular/core';
import {INoRowsOverlayAngularComp} from 'ag-grid-angular';
import {INoRowsOverlayParams} from 'ag-grid-community';
import {TranslateModule} from '@ngx-translate/core';

@Component({
  standalone: true,
  selector: 'app-no-rows-overlay',
  template: `
    <div class="ag-overlay-no-rows-wrapper">
      {{ "Table.NoData" | translate }}
    </div>
  `,
  imports: [
    TranslateModule
  ]
})
export class CustomNoRowsOverlayComponent implements INoRowsOverlayAngularComp {
  public params!: INoRowsOverlayParams & { noRowsMessageFunc: () => string };

  agInit(
    params: INoRowsOverlayParams & { noRowsMessageFunc: () => string }
  ): void {
    this.params = params;
  }
}
